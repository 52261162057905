<template>
  <div >
          <subMap ref="subMap" :layer-name="layerName" :layer-code="layerCode" />
  
  </div>
</template>

<script>
import { LoadMixin } from '@/common/amapLoad'
import subMap from '@/views/mapTool/subMap'
export default {
  name: 'GisMap',
  components: {subMap },
  mixins: [LoadMixin],
  data() {
    return {
      name: 'GIS一张图',
      secondTitle: '',
      isCollapse: true,
      menuList: [],
      active: '',
      layerName: '',
      layerCode: ''
    }
  },
  computed: {},
  mounted() {
   
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.mainBox {
  height: 100%;
  width: 100%;
  background: #f3f4f7;
}
</style>
